import './SearchByDetailsPage.css';
import {Dimmer, Grid, Icon, Pagination, Table} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {getCart} from "../../utils/cartHelper";
import {api} from "../../utils/api";
import {pluralize} from "numeralize-ru";
import SearchControl from "../../shared/searchControl/searchControl";
import DealersForProduct from "../../shared/dealersForProduct/dealersForProduct";
import Loader from "../../shared/loader/loader";
import {useNavigate} from "react-router-dom";
import TweenOne from "rc-tween-one";

export default function SearchByDetailsPage() {
  let [viewedProductItemId, setViewedProductItemId] = useState(null);
  let [cart, setCart] = useState([]);
  let [activePage, setActivePage] = useState(1);
  let [detailsData, setDetailsData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [totalPages, setTotalPages] = useState(0);
  let [nameForSearch, setNameForSearch] = useState('');
  let [articleForSearch, setArticleForSearch] = useState('');
  let [showLoader, setShowLoader] = useState(true);
  let [isAnimationStarted, setIsAnimationStarted] = useState(false);
  const navigate  = useNavigate();
  
  let load = (page) => {
    setShowLoader(true);
    api().getDetails(page, nameForSearch, articleForSearch).then((resp)=>{
      setDetailsData(resp.PartNumberShortInfo.map(pr=> {return {
        id: pr.Item,
        number: pr.RowNumber,
        article: pr.Item,
        name: pr.Descr,
        price: pr.AvgPrice == null ? '-' : (pr.AvgPrice.toLocaleString('ru-RU') + (pr.AvgPrice.toLocaleString('ru-RU').includes(',') ? '' : ',00')),
        dealerCount: pr.DealersQnt
      };}));
      setTotalCount(resp.PartNumberHeader.TotalRows);
      setTotalPages(resp.PartNumberHeader.PagesCount);
      setShowLoader(false);
      setActivePage(page);
    });
  };
  
  useEffect(()=>{
    setCart(getCart());
    load(1);
  }, []);
  
  let resultsForView = [];
  if(viewedProductItemId != null) {
    resultsForView = detailsData.filter(x=>x.id === viewedProductItemId);
  } else{
    resultsForView = detailsData;
  }
  
  return <div>
    <Grid>
      <Grid.Row>
        <Grid.Column width={1}><a href={'/'} onClick={(e)=>{
          e.stopPropagation();
          e.preventDefault();
          if(!isAnimationStarted) {
            setIsAnimationStarted(true);
            setTimeout(()=>{
              setIsAnimationStarted(false);
              navigate("/");
            }, 1000);
          }
          return false;
        }} ><TweenOne
            animation={{
              x: 335,
              y: 60,
              scale: 3,
              yoyo: true,
              repeat: -1,
              duration: 1000
            }}
            paused={!isAnimationStarted}
        ><img style={{zIndex: '999999'}} src={'/img/details-small.svg'}/></TweenOne></a></Grid.Column>
        <Grid.Column width={7}>
          <SearchControl 
              disabled={viewedProductItemId !== null} 
              value={nameForSearch} 
              onChange={(e) => { setNameForSearch(e.target.value)}}
              onEnterPress={()=>{load(1)}}
              placeholder={'Поиск по наименованию'}
          />
        </Grid.Column>
        <Grid.Column width={7}>
          <SearchControl
              disabled={viewedProductItemId !== null}
              value={articleForSearch}
              onChange={(e) => { setArticleForSearch(e.target.value)}}
              onEnterPress={()=>{load(1)}}
              placeholder={'Поиск по артикулу'}
          />
        </Grid.Column>
        <Grid.Column width={1} style={{textAlign: 'right'}}>
          <a href={'/shoppingCart'} ><img src={'/img/cart.svg'} />{cart.length > 0 ? <span className={'shopping-cart-label'}>{cart.length}</span> : ''}</a>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <div style={{marginTop: '30px'}}>
      {viewedProductItemId !== null ? <><Icon onClick={()=>{setViewedProductItemId(null)}} style={{cursor: 'pointer'}} name={'arrow left'} />&nbsp;&nbsp;</> : ''}
      <span className={'header-label'}>Запчасти</span> 
      <span className={'result-count-label'}>&nbsp;&nbsp;&nbsp;&nbsp; {totalCount.toLocaleString('ru-RU')} {pluralize(totalCount, 'результат', 'результата', 'результатов')}</span>
    </div>
    <div className={'products-table-container'}>
      {showLoader ? <Loader /> : ''}
      {resultsForView.length > 0 && !showLoader ? <Table celled striped style={{transition: 'height 2s ease-in'}}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>№</Table.HeaderCell>
            <Table.HeaderCell>Артикул детали</Table.HeaderCell>
            <Table.HeaderCell>Наменование</Table.HeaderCell>
            <Table.HeaderCell>Средняя цена (руб.)</Table.HeaderCell>
            <Table.HeaderCell>Дилеров</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {resultsForView.map(item => <Table.Row className={'product-item-in-table' + (viewedProductItemId != null ? ' selected' : '')} onClick={()=>{
            if(viewedProductItemId == null){
              setViewedProductItemId(item.id);
            }
            else
              setViewedProductItemId(null);
          }}>
            <Table.Cell>{item.number}</Table.Cell>
            <Table.Cell>
              {item.article}
            </Table.Cell>
            <Table.Cell>{item.name}</Table.Cell>
            <Table.Cell>{item.price}</Table.Cell>
            <Table.Cell>{item.dealerCount}</Table.Cell>
          </Table.Row>)}
        </Table.Body>
      </Table> : ''}
      {resultsForView.length === 0 && !showLoader ? <div className={'not-found-result-container'}>
        <img src={'/img/not-found.svg'} />
        <p>Нет результатов</p>
      </div> : '' }
    </div>

    {viewedProductItemId == null && !showLoader ? <Pagination
        defaultActivePage={1}
        activePage={activePage}
        firstItem={null}
        lastItem={null}
        nextItem={null}
        prevItem={null}
        totalPages={totalPages}
        onPageChange={(e, data)=>{
          load(data.activePage);
        }}
    /> : ''}

    {viewedProductItemId !== null ? <DealersForProduct 
        product={detailsData.filter(x => x.id === viewedProductItemId)[0]}
        onChange={()=>{
          setCart(getCart());
        }}
    /> : ''}
    
  </div>;
}
