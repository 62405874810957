import './countInput.css';
import {Icon} from "semantic-ui-react";

export default function CountInput({value, onChange}) {
  return <div className={'count-input-container'}>
    <input type={'number'} value={value} onChange={(e) => { onChange(e.target.value) }}/>
    <Icon className={'up-btn-for-count'} name={'chevron up'} onClick={()=>{
      onChange(parseInt(value) + 1, true);
    }}/>
    <Icon className={'down-btn-for-count'} name={'chevron down'}  onClick={()=>{ onChange(parseInt(value) - 1) }} />
  </div>;
}