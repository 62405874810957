import './AccessoryDetailsPage.css';
import {Button, Grid, Header, Icon, Image, Modal, Visibility} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {api} from "../../../utils/api";
import DealersForProduct from "../../../shared/dealersForProduct/dealersForProduct";
import {getCart} from "../../../utils/cartHelper";

function AccessoryDetailsPage({accessory, onClickBack}) {
    let [openModalForGoToCart, setOpenModalForGoToCart] = useState(false);
    let [cart, setCart] = useState([]);
    
  let properties = [
      {name: 'Номинальная мощность', value: '35 Вт'},
      {name: 'Максимальная мощность', value: '250 Вт'},
      {name: 'Количество полос', value: '3'},
      {name: 'Частотный диапазон', value: '44 Гц - 31 кГц'},
      {name: 'Сопротивление', value: '4 Ом'}
  ];
    useEffect(()=>{
        setCart(getCart());
    }, []);
  return <div>
    <Grid>
      <Grid.Row>
        <Grid.Column width={13}>
            <div className={'header-with-arrow-back'}>
                <Icon name={'arrow left'} onClick={onClickBack} className={'back-btn'}/> 
                <h3>{accessory.name}</h3>
            </div>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <p>Вот вам яркий пример современных тенденций - повышение уровня гражданского сознания способствует подготовке и реализации стандартных подходов. Банальные, но неопровержимые выводы, а также тщательные.</p>
                        <div className={'price-container'}>Средняя цена: <span className={'price'}>{accessory.price !== null ? accessory.price.toLocaleString('ru-RU') + ' ₽' : '-'}</span></div>
                        <h4>Характеристики</h4>
                        <div className={'property-container'}>
                            {properties.map(property => <div className={'property-item'} style={{backgroundImage: "url('/img/property-item-background.svg')"}}>
                                <span className={'property-item-name'}>{property.name}</span>
                                <div className={'property-item-value'}>{property.value}</div>
                            </div>)}
                        </div>                        
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div className={'image-container'}><img src={accessory.image == null ? '/img/no-available-image.svg' : 'data:image/png;base64, ' + accessory.image}/></div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Grid.Column>
        <Grid.Column width={3} style={{textAlign: 'right'}}><a href={'/shoppingCart'} ><img src={'/img/cart.svg'} />{cart.length > 0 ? <span className={'shopping-cart-label'}>{cart.length}</span> : ''}</a><br/>Тут описание с этим товаром также берут</Grid.Column>
      </Grid.Row>
    </Grid>
    <DealersForProduct
      product={accessory}
      onChange={(isAddition)=>{
          setCart(getCart());
          if(isAddition && (localStorage.getItem('isAlreadyCloseModalForGoToCart') === null ||
              (Date.now() - parseInt(localStorage.getItem('isAlreadyCloseModalForGoToCart'))) > 1000 * 60 * 60 ))
              setOpenModalForGoToCart(true);
      }}
    />
      <Modal
          onClose={() => {
              setOpenModalForGoToCart(false);
              localStorage.setItem('isAlreadyCloseModalForGoToCart', Date.now().toString());
          }}
          onOpen={() => setOpenModalForGoToCart(true)}
          open={openModalForGoToCart}
          size={'small'}
          style={{borderRadius: '0'}}
      >
          <Modal.Content>
              <Grid className={'go-to-cart-modal-content'}>
                  <Grid.Row>
                      <Grid.Column width={8}><Image size='medium' src='/img/icon-added.png' wrapped centered style={{paddingLeft: '70px'}}/></Grid.Column>
                      <Grid.Column width={8} style={{textAlign: 'center', paddingTop: '80px'}}>
                          <Header>Товар добавлен!</Header>
                          <Button className={'go-to-cart-btn'} href={"/shoppingCart"}><img src={'/img/cart-white.svg'}/>&nbsp;Перейти в корзину</Button>
                          <Button className={'continue-shopping-btn'} href={"#"}>Продолжить покупки</Button>
                      </Grid.Column>
                  </Grid.Row>
              </Grid>
          </Modal.Content>
      </Modal>
  </div>;
}

export default AccessoryDetailsPage;
