import './header.css';
import {Container, Grid} from "semantic-ui-react";

function Header() {
  return <div className={'main-menu'}>
    <Container>
      <Grid>
        <Grid.Row style={{padding: '0'}}>
          <Grid.Column width={3} textAlign={'left'} style={{paddingTop: '10px'}}><a href={'/'}><img src={'/img/logo.png'}  alt={'menu'}/></a></Grid.Column>
          <Grid.Column width={10} style={{paddingTop: '10px'}}> <img src={'/img/menu-points.png'}  alt={'menu'}/></Grid.Column>
          <Grid.Column width={3}><img src={'/img/end-of-menu.png'}  alt={'menu'}/></Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </div>;
}

export default Header;
