
export function getCart() {
    let cart = localStorage.getItem('cart') ?? '[]';
    cart = JSON.parse(cart);
    return cart;
}

export function clearCart() {
    localStorage.setItem('cart', JSON.stringify([]));
}
export function addToCart(dealerName, dealerId, productName, productId, price) {
    let cart = getCart();
    cart.push({
        dealerName: dealerName,
        dealerId: dealerId,
        productName: productName,
        productId: productId,
        count: 1,
        price: price
    });
    localStorage.setItem('cart', JSON.stringify(cart));
}

export function setProductCount(dealerId, productId, count) {
    let cart = getCart();
    
    let productInCart = cart.filter(x=> x.productId === productId && x.dealerId === dealerId)[0];
    if(count === 0) {
        cart = cart.filter(x=> !(x.productId === productId && x.dealerId === dealerId));
        localStorage.setItem('cart', JSON.stringify(cart));
    } else {
        productInCart.count = count;
        localStorage.setItem('cart', JSON.stringify(cart));
    }
}