import './sortSelector.css';
import {Dropdown} from "semantic-ui-react";

export default function SortSelector({value, onChange}) {
  return <Dropdown
      className={'sort-selector'}
      placeholder='Сортировка по цене'
      fluid
      selection
      value={value}
      onChange={(event, data) => { onChange(data.value)}}
      options={[
        { key: 'price-asc', value: 'price-asc', text: 'Цена по возрастанию' },
        { key: 'price-desc', value: 'price-desc', text: 'Цена по убыванию' },
      ]}
  />;
}