import './SearchByAccessoriesPage.css';
import {Button, Grid, Visibility} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {getCart} from "../../utils/cartHelper";
import {api} from "../../utils/api";
import SearchControl from "../../shared/searchControl/searchControl";
import CitySelector from "../../shared/citySelector/citySelector";
import SortSelector from "../../shared/sortSelector/sortSelector";
import {pluralize} from "numeralize-ru";
import AccessoryDetailsPage from "./components/AccessoryDetailsPage";

function SearchByAccessoriesPage() {
  let [nameForSearch, setNameForSearch] = useState('');
  let [articleForSearch, setArticleForSearch] = useState('');
  let [cityForSearch, setCityForSearch] = useState('');
  let [cart, setCart] = useState([]);
  let [categories, setCategories] = useState([]);
  let [currentCategory, setCurrentCategory] = useState(null);
  let [priceSort, setPriceSort] = useState('price-asc');
  let [totalCount, setTotalCount] = useState(0);
  let [viewType, setViewType] = useState('cards');
  let [accessories, setAccessories] = useState([]);
  let [currentLoadedPage, setCurrentLoadedPage] = useState(1);
  let [scrollPosition, setScrollPosition] = useState(0);
  let [focusedAccessory, setFocusedAccessory] = useState(null);
  
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  
  let loadCategories = () => {
    api().getCategories().then((categories)=>{
      setCategories(categories.map(c=> {return {
        id: c.ItemGroupId,
        name: c.Descr,
        count: c.Qnt
      };}))
    });
  };
  let loadAccessories = (page, priceSort, category, nameForSearch, article, city) => {
    api().getAccessories(page, priceSort, category, nameForSearch, article, city).then(data=>{
      let loadedAccessoriesForPage = data.PartNumberShortInfo.map(x => {
        return {
          id: x.Item,
          article: x.Item,
          name: x.Descr,
          image: x.ItemImage,
          price: x.AvgPrice,
        };
      });
      if(page > 1) {
        let temp = accessories.concat(loadedAccessoriesForPage);
        setAccessories(temp);
      }else{
        setAccessories(loadedAccessoriesForPage);
      }
      setTotalCount(data.PartNumberHeader.TotalRows);
    });
  };

  useEffect(()=>{
    setCart(getCart());
    loadAccessories(1);
    loadCategories();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  let disabledClearBtn = currentCategory === null;
  
  if(focusedAccessory != null)
    return <AccessoryDetailsPage 
        accessory={focusedAccessory}
        onClickBack={()=>{
          setFocusedAccessory(null);
          setCart(getCart());
        }}
    />;
  
  return <div>
    <Grid>
      <Grid.Row>
        <Grid.Column width={1}><a href={'/'}><img src={'/img/accessories-small.svg'}/></a></Grid.Column>
        <Grid.Column width={7}>
          <SearchControl
              disabled={false}
              value={nameForSearch}
              onChange={(e) => { setNameForSearch(e.target.value)}}
              onEnterPress={()=>{setCurrentLoadedPage(1);loadAccessories(1, priceSort, currentCategory, nameForSearch, articleForSearch, cityForSearch)}}
              placeholder={'Поиск по наименованию'}
          />
        </Grid.Column>
        <Grid.Column width={7}>
          <SearchControl
              disabled={false}
              value={articleForSearch}
              onChange={(e) => { setArticleForSearch(e.target.value)}}
              onEnterPress={()=>{setCurrentLoadedPage(1);loadAccessories(1, priceSort, currentCategory, nameForSearch, articleForSearch, cityForSearch)}}
              placeholder={'Поиск по артикулу'}
          />
        </Grid.Column>
        <Grid.Column width={1} style={{textAlign: 'right'}}>
          <a href={'/shoppingCart'} ><img src={'/img/cart.svg'} />{cart.length > 0 ? <span className={'shopping-cart-label'}>{cart.length}</span> : ''}</a>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Grid>
      <Grid.Row>
        <Grid.Column width={4} className={'categories-container'}>
          <div className={'first-header'}><img src={'/img/filter.svg'}/>Фильтр</div>
          <div className={'second-header'}>Категории</div>
          {categories.map(category => <div className={'category-item' + (currentCategory === category.id ? ' active' : '')} onClick={()=>{
            let newCategory = null
            if(currentCategory === category.id){
              newCategory = null; 
            }else{
              newCategory = category.id; 
            }
            setCurrentCategory(newCategory);
            setCurrentLoadedPage(1);
            loadAccessories(1, priceSort, newCategory, nameForSearch, articleForSearch, cityForSearch)
          }}>
            {category.name}
            <span className={'count'}>{category.count}</span>
          </div>)}
          <Button className={'clear-filter-btn' + (disabledClearBtn ? ' disabled' : '')} disabled={disabledClearBtn} onClick={()=>{
            setCurrentCategory(null);
            setCurrentLoadedPage(1);
            loadAccessories(1, priceSort, null, nameForSearch, articleForSearch, cityForSearch);
          }}><img src={'/img/filter-clear'+(disabledClearBtn ? '-disabled' : '') +'.svg'}/>&nbsp;Очистить</Button>

          {scrollPosition > 1000 ? <img className={'to-up-btn'} src={'/img/to-up.svg'} onClick={()=>{
            window.scrollTo(0, 0);
          }} /> : ''}
        </Grid.Column>
        <Grid.Column width={12}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4} style={{paddingTop: '12px', color: '#B3B3B3', paddingLeft: '29px'}}>{totalCount.toLocaleString('ru-RU')} {pluralize(totalCount, 'результат', 'результата', 'результатов')}</Grid.Column>
              <Grid.Column width={5}><CitySelector value={cityForSearch} onChange={(val)=> {setCityForSearch(val);
                setCurrentLoadedPage(1);
                loadAccessories(1, priceSort, currentCategory, nameForSearch, articleForSearch, val)}}/> </Grid.Column>
              <Grid.Column width={5}><SortSelector value={priceSort} onChange={val=> { setPriceSort(val); setCurrentLoadedPage(1); loadAccessories(1, val, currentCategory, nameForSearch, articleForSearch, cityForSearch)}}/></Grid.Column>
              <Grid.Column width={2} className={'view-selector'}>
                <img src={'/img/card-view'+(viewType === 'cards' ? '-active' : '')+'.svg'} onClick={()=>{
                  setViewType('cards');
                }}/>
                <img src={'/img/list-view'+(viewType === 'list' ? '-active' : '')+'.svg'} onClick={()=>{
                  setViewType('list');
                }}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
            <div className={'accessories-'+viewType+'-container'}>
              {accessories.map((accessory, index) => <div className={'accessory-item-for-'+viewType} onClick={()=>{ setFocusedAccessory(accessory)}}>
                <div>
                  <div className={'image-container'}><img src={accessory.image == null ? '/img/no-available-image.svg' : 'data:image/png;base64, ' + accessory.image}/></div>
                  <div className={'name'}>{accessory.name}</div>
                  <div className={'price'}>{accessory.price !== null ? accessory.price.toLocaleString('ru-RU') + ' ₽' : ''}</div>
                </div>
                {index === accessories.length - 1 ? <Visibility onBottomVisible={() => {
                  let newPage = currentLoadedPage + 1;
                  setCurrentLoadedPage(newPage);
                  loadAccessories(newPage, priceSort, currentCategory, nameForSearch, articleForSearch, cityForSearch);
                }}/>: ''}
              </div>)}
            </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>

  </div>;
}

export default SearchByAccessoriesPage;
