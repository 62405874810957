export function api() {
    let baseApi = 'https://api-pro.logicstars.ru/api/';
    let authUrl = 'https://auth.logicstars.ru/api/guesttoken/chery';
    let getToken = () => {return localStorage.getItem('token');};
    let setToken = (token) => {localStorage.setItem('token', token);};
    
    let get = (url) => {
        return innerCall('GET', url);
    };
    
    let post = (url, body) => {
        return innerCall('POST', url, body);
    };
    
    let innerCall = (method, url, body) => {
        let call = (url, token) => {
            let initForFetch = {
                headers: {
                    'authorization': 'Bearer ' + token
                },
                method: method,
            };
            if(method === 'POST' && body !== undefined && body !== null){
                const formData  = new FormData();
                for(const name in body)
                    formData.append(name, body[name]);
                
                initForFetch.body = formData;
            }
            return  fetch(url, initForFetch)
            .then((response) => {
                return response.json();
            });
        };

        if(getToken() === null) {
            return fetch(authUrl)
                .then((response) => {
                    return response.text();
                })
                .then(token => {
                    setToken(token);
                    return call(url, token); 
                });
        }    
        return call(url, localStorage.getItem('token'));
    };
    return {
        getDetails: (page, name, article) => {
            return get(baseApi + 'PartInfo/GetList?Mode=3&PageNumber=' + (page ?? 1) + 
                (name !== undefined && name !== null &&name !== ''? '&Description=' + name : '') + 
                (article !== undefined && article !== null && article !== ''? '&Items=' + article : '')
            );
        },
        getDealersForArticle: (article, city) => {
            return get(baseApi + 'PartInfo/GetDealerData?item=' + article + (city !== undefined && city !== null && city !== ''? '&city=' + city : ''));
        },
        getCategories: () => {
            return get(baseApi + 'PartInfo/GetItemGroups');
        },
        getAccessories: (page, priceSort, category, name, article, city) => {
            return get(baseApi + 'PartInfo/GetList?Mode=2&PageNumber='+ (page ?? 1) +'&SortMode='+(priceSort === 'price-desc' ? '2' : '1')+
                (name !== undefined && name !== null &&name !== ''? '&Description=' + name : '') +
                (article !== undefined && article !== null && article !== ''? '&Items=' + article : '') +
                (category !== undefined && category !== null && category !== ''? '&ItemGroupId=' + category : '') +
                (city !== undefined && city !== null && city !== ''? '&City=' + city : '')
            );
        },
        getPersonalData: () => {
            return get(baseApi + 'PersonalData');
        },
        createRequest: (dealerPointId, surname, name, patronymic, phone, email, data, notes) => {
            return post(baseApi + 'PartInfo/CreateItemOrderPublic', {
                DealerPointId: dealerPointId,
                Surname: surname,
                Name: name,
                Patronymic: patronymic,
                Phone: phone,
                Email: email,
                Data: data,
                Notes: notes
            });
        }
    };
}