import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import './App.css';
import StartPage from "./pages/start/StartPage";
import SearchByDetailsPage from "./pages/searchByDetails/SearchByDetailsPage";
import SearchByAccessoriesPage from "./pages/searchByAccessories/SearchByAccessoriesPage";
import ShoppingCartPage from "./pages/shoppingСart/ShoppingCartPage";
import {Container, Grid} from "semantic-ui-react";
import Header from "./header/header";

function App() {
  return (
      <BrowserRouter>
          <Header />
          <Container>
              <Routes>
                  <Route path="/" element={<StartPage/>}/>
                  <Route path="/searchByDetails" element={<SearchByDetailsPage/>}/>
                  <Route path="/searchByAccessories" element={<SearchByAccessoriesPage/>}/>
                  <Route path="/shoppingCart" element={<ShoppingCartPage/>}/>
              </Routes>
          </Container>
      </BrowserRouter>
  );
}

export default App;
