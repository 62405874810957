import './ShoppingСartPage.css';
import {Button, Checkbox, Dropdown, Form, Grid, Icon, Input, Modal, TextArea} from "semantic-ui-react";
import {clearCart, getCart, setProductCount} from "../../utils/cartHelper";
import {useEffect, useState} from "react";
import CountInput from "../../shared/countInput/countInput";
import {pluralize} from "numeralize-ru";
import {api} from "../../utils/api";
import {replaceSpaceToNbsp} from "../../utils/stringHelper";

function ShoppingCartPage() {
  let [cart, setCart] = useState([]);
  let [openConfirmForClear, setOpenConfirmForClear] = useState(false);
  let [openModalAfterSendRequest, setOpenModalAfterSendRequest] = useState(false);
  let [openPersonalConfirmationModal, setOpenPersonalConfirmationModal] = useState(false);
  let [personalConfirmationText, setPersonalConfirmationText] = useState('');
  let [dealerPoint, setDealerPoint] = useState(null);
  let [name, setName] = useState('');
  let [phone, setPhone] = useState('');
  let [email, setEmail] = useState('');
  let [comment, setComment] = useState('');
  let [processingOfPersonalData, setProcessingOfPersonalData] = useState(false);
  let [validateErrors, setValidateErrors] = useState([]);


  let getDealerNames = (cart) => {
    return cart
        .map(x=>x.dealerName)
        .filter(function (value, index, self) {
          return self.indexOf(value) === index;
        });    
  };
  
  let getOptionsForPoints = (cart) => {
    let dealerNames = getDealerNames(cart);

    return dealerNames
        .map(dealerName => {return {key: dealerName, text: dealerName, value: dealerName};});
  }; 
  
  useEffect(()=>{
    let cart1 = getCart();
    setCart(cart1);
    let optionsForPoints = getOptionsForPoints(cart1);
    if(optionsForPoints.length === 1){
      setDealerPoint(optionsForPoints[0].value);
    }
  }, []);

  let sumCart = function (cart) {
    let sum = 0;
    cart.map(cartItem => {
      sum += cartItem.count * cartItem.price; 
    });
    return replaceSpaceToNbsp(sum.toLocaleString('ru-RU') + ' ₽');
  }
  
  let createRequest = function () {
    let validateErrorsTemp = [];
    if(dealerPoint === null)
      validateErrorsTemp.push('dealerPoint');
    
    if(name === '')
      validateErrorsTemp.push('name');
    
    if(phone === '')
      validateErrorsTemp.push('phone');
    
    if(email === '')
      validateErrorsTemp.push('email');
    
    if(!processingOfPersonalData)
      validateErrorsTemp.push('processingOfPersonalData');
    
    if(validateErrorsTemp.length > 0)
      setValidateErrors(validateErrorsTemp);
    else {
      let dealerId = cart.filter(x=>x.dealerName === dealerPoint)[0].dealerId;
      api().createRequest(dealerId, 
          name, 
          name, 
          name, 
          phone, 
          email, 
          JSON.stringify(cart), 
          comment).then(resp => {
            if(resp.ErrorInfo === '')
              setOpenModalAfterSendRequest(true);
            else 
              alert(resp.ErrorInfo);
      });
    }
  };

  return <div>
    <Grid>
      <Grid.Row>
        <Grid.Column width={12}>
          <a href={'/'}>
            <Icon name={'arrow left'} className={'back-btn'}/>
          </a>
          <h2 style={{display:'inline'}}>&nbsp;&nbsp;&nbsp;Корзина</h2>
          <Grid className={'cart-header'}>
            <Grid.Row>
              <Grid.Column width={8}><b>Наименование</b></Grid.Column>
              <Grid.Column width={2} className={'text-align-center'}><b>Цена</b></Grid.Column>
              <Grid.Column width={2} className={'text-align-center'}><b>Кол-во</b></Grid.Column>
              <Grid.Column width={3} className={'text-align-center'}><b>Конечная цена</b></Grid.Column>
              <Grid.Column width={1}/>
            </Grid.Row>
          </Grid>
          {cart.length > 0 ? <div>
            <div>
              {getDealerNames(cart).map(dealerName => <div className={'dealer-cart-container'}>
                <div className={'dealer-name'}>
                  <img src={'/img/pin.svg'} />
                  {dealerName}
                </div>
                {cart.filter(x=>x.dealerName === dealerName).map(cartItem => <div>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={8}>{cartItem.productName}</Grid.Column>
                      <Grid.Column width={2} className={'price-column'}>
                        {cartItem.price == null ? '-' : replaceSpaceToNbsp(cartItem.price.toLocaleString('ru-RU') + ' ₽')}
                      </Grid.Column>
                      <Grid.Column width={2} className={'text-align-center'}>
                        <CountInput value={cartItem.count} onChange={(val)=>{
                        setProductCount(cartItem.dealerId, cartItem.productId, val);
                        setCart(getCart());
                      }}/></Grid.Column>
                      <Grid.Column width={3} className={'price-column'}>
                        {replaceSpaceToNbsp((cartItem.price * cartItem.count).toLocaleString('ru-RU') + ' ₽')}
                      </Grid.Column>
                      <Grid.Column width={1}><img src={'/img/delete.svg'} onClick={()=>{
                        setProductCount(cartItem.dealerId, cartItem.productId, 0);
                        setCart(getCart());
                      }} style={{cursor: 'pointer'}} /></Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>)}
              </div>)}
              
            </div>
            <Button className={'clear-cart-btn'} onClick={()=>{
              setOpenConfirmForClear(true);
            }}>Очистить корзину</Button>
          </div> : <div className={'text-align-center'}>
            <a href={'/'}><img src={'/img/cart-is-empty.svg'} /></a>
          </div>}
          
        </Grid.Column>
        <Grid.Column width={4} className={'create-request-container'}>
          <h1>Оформление</h1>
          <Form style={{marginBottom: '15px'}}>
            <Form.Field>
              <Dropdown error={validateErrors.includes('dealerPoint')} value={dealerPoint} onChange={(e, data)=> {
                setDealerPoint(data.value);
                setValidateErrors(validateErrors.filter(x=>x !== 'dealerPoint'))
              }} selection fluid placeholder={'Точка заказа'} options={getOptionsForPoints(cart)} />
              <img className={'pin'} src={'/img/pin.svg'} />
            </Form.Field>
            <Form.Field>
              <Input error={validateErrors.includes('name')} value={name} onChange={(e)=>{ setName(e.target.value)}} placeholder={'Ваше Имя'} />
            </Form.Field>
            <Form.Field>
              <Input error={validateErrors.includes('phone')} value={phone} onChange={(e)=>{ setPhone(e.target.value)}} placeholder={'Ваш телефон'} />
            </Form.Field>
            <Form.Field>
              <Input error={validateErrors.includes('email')} value={email} onChange={(e)=>{ setEmail(e.target.value)}} placeholder={'Ваш e-mail'} />
            </Form.Field>
            <Form.Field>
              <TextArea error={validateErrors.includes('comment')} value={comment} onChange={(e)=>{ setComment(e.target.value)}} placeholder={'Примечание'} />
            </Form.Field>
          </Form>
          
          <p>Способы оплаты и доставки согласовываются с дилером после оформления заказа</p>
          <hr/>
          <div className={'total-price-for-all-products'}>Итого: {cart.length} {pluralize(cart.length, 'товар', 'товара', 'товаров')} на {sumCart(cart)}</div>
          <Grid style={{marginLeft: '0'}}>
            <Grid.Row>
              <Grid.Column width={2} style={{padding: '0', paddingTop: '1px'}}><Checkbox
                  checked={processingOfPersonalData}
                  onChange={(e, { checked })=>{
                    setProcessingOfPersonalData(checked);
                    setValidateErrors(validateErrors.filter(x=>x !== 'processingOfPersonalData'))
                  }}  
                  className={validateErrors.includes('processingOfPersonalData') ? 'error' : ''}/></Grid.Column>
              <Grid.Column width={14} style={{padding: '0'}}>
                Я даю согласие на обработку <a onClick={()=>{
                  setOpenPersonalConfirmationModal(true);
                  api().getPersonalData().then(resp => setPersonalConfirmationText(resp.Descr))
                }} style={{color: '#0156FF', textDecoration:'underline', cursor: 'pointer'}} >персональных даннных</a>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Button className={'create-request-btn'} onClick={createRequest}>Оформить заказ</Button>
          {cart.length === 0 ? <div className={'cover-for-disabled-form'}/> : ''}
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Modal
        onClose={() => {
          setOpenConfirmForClear(false);
        }}
        onOpen={() => setOpenConfirmForClear(true)}
        open={openConfirmForClear}
        size={'mini'}
        className={'clear-modal-confirm'}
    >
      <Modal.Content>
        <p>Вы уверены что хотите очистить корзину?</p>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} onClick={()=> {
              setOpenConfirmForClear(false);
            }}><b>Нет</b></Grid.Column>
            <Grid.Column width={8} onClick={()=> {
              clearCart();
              setCart(getCart());
              setOpenConfirmForClear(false);
            }}>Да</Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>

    <Modal
        onClose={() => {
          setOpenModalAfterSendRequest(false);
        }}
        onOpen={() => setOpenModalAfterSendRequest(true)}
        open={openModalAfterSendRequest}
        size={'small'}
        className={'after-send-request-modal'}
    >
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <img src={'/img/img-for-modal-after-send.png'}/>
            </Grid.Column>
            <Grid.Column width={8}>
              <h1>Заказ оформлен!</h1>
              <p>В ближайшее время с вами свяжется оператор для уточнения деталей</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
    <Modal
        onClose={() => {
          setOpenPersonalConfirmationModal(false);
        }}
        onOpen={() => setOpenPersonalConfirmationModal(true)}
        open={openPersonalConfirmationModal}
        size={'small'}
        className={'personal-confirmation-modal'}
        closeIcon
    >
      <Modal.Content scrolling>
        <div>{personalConfirmationText.split('\r\n').map((line, i) => (
            <p key={i}>
            {line}
        </p>
        ))}</div>
      </Modal.Content>
      <Modal.Actions>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} style={{textAlign: 'left'}}><Button className={'cancel-btn'} onClick={()=>{setOpenPersonalConfirmationModal(false);}}>Отмена</Button></Grid.Column>
            <Grid.Column width={8}><Button className={'agree-btn'} onClick={()=>{setOpenPersonalConfirmationModal(false);setProcessingOfPersonalData(true);}}><img src={'/img/chekbox.svg'}/>Я согласен (на)</Button></Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Actions>
    </Modal>
  </div>;
}

export default ShoppingCartPage;