import './citySelector.css';
import {Dropdown} from "semantic-ui-react";

export default function CitySelector({value, onChange}) {
  return <Dropdown
      value={value}
      onChange={(event, data) => { onChange(data.value)}}
      className={'city-selector'}
      placeholder='Город'
      fluid
      search
      selection
      clearable
      options={[
        { key: 'Москва', value: 'Москва', text: 'Москва' },
        { key: 'Санкт-Петербург', value: 'Санкт-Петербург', text: 'Санкт-Петербург' },
        { key: 'Екатеринбург', value: 'Екатеринбург', text: 'Екатеринбург' },
        { key: 'Казань', value: 'Казань', text: 'Казань' },
        { key: 'Нижний Новгород', value: 'Нижний Новгород', text: 'Нижний Новгород' },
        { key: 'Челябинск', value: 'Челябинск', text: 'Челябинск' },
        { key: 'Самара', value: 'Самара', text: 'Самара' },
        { key: 'Омск', value: 'Омск', text: 'Омск' },
      ]}
  />;
}