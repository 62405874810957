import './StartPage.css';
import {Grid} from "semantic-ui-react";
import TweenOne from "rc-tween-one";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

function StartPage() {
  let [isAnimationStarted, setIsAnimationStarted] = useState(false);
  const navigate  = useNavigate();
  
  return <div className={'start-page' + (isAnimationStarted ? ' fade-out' : '')}>
    <Grid style={{width: '80%', paddingLeft: '217px'}}>
      <Grid.Row>
        <Grid.Column width={8}>
          <a href={'/searchByDetails'} onClick={(e)=>{
            e.stopPropagation(); 
            e.preventDefault(); 
            if(!isAnimationStarted){
              setIsAnimationStarted(true);
              setTimeout(()=>{
                setIsAnimationStarted(false);
                navigate("/searchByDetails");
              }, 450);
            }
            return false;
          }}>
            <div className={'switch-icon-container'}>
              <TweenOne
                  animation={{
                    x: -335,
                    y: -60,
                    scale: 0.3,
                    yoyo: true, 
                    repeat: -1, 
                    duration: 500
                  }}
                  paused={!isAnimationStarted}
              ><img className={isAnimationStarted ? 'isAnimatedElement' : ''} src={'/img/details.svg'} alt={'Поиск запасных частей'} style={{marginBottom: '20px'}}/></TweenOne>
            </div>
            <div style={{fontWeight: 'bold', fontSize: '18px', color: 'black', paddingTop: '14px'}}>Поиск запасных частей</div>
          </a>
        </Grid.Column>
        <Grid.Column width={8}>
          <a href={'/searchByAccessories'}>
            <div className={'switch-icon-container'}>
              <img src={'/img/accessories.svg'} alt={'Поиск оригинальных аксуссуаров'} style={{marginBottom: '20px'}}/>
            </div>
            <div style={{fontWeight: 'bold', fontSize: '18px', color: 'black', paddingTop: '14px'}}>Оригинальные аксессуары</div>
          </a>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <div className={'switch-container'}>
      <div className={'description'}>
        Рекомендации всех автопроизводителей - это использование оригинальных запасных частей, качество которых<br/> подтверждено испытаниями на соответствие требованиям безопасности и стандартам качества<br/> соответствующего производителя.
      </div>
      <Grid>
        <Grid.Row>
          <img src={'/img/phone.png'} style={{position: 'absolute', top: '0', left: '-166px'}}/>
          <Grid.Column width={8} className={'links-to-apps'}>
            <div style={{width: '223px', textAlign: 'left', position: 'absolute', right: '42px', top: '110px'}}>
              <div style={{fontWeight: 'bold', fontSize: '18px', marginBottom: '20px'}}>В приложении</div>
              <div style={{marginBottom: '20px'}}><a 
                  href={'https://apps.apple.com/ru/app/%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9-%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82-chery/id1571172622'}
                  target={'_blank'}
                  rel={'nofollow'}
              ><img src={'/img/app store.png'}/></a></div>
              <div><a 
                  href={'https://play.google.com/store/apps/details?id=ru.logicstar.CheryPersonal&hl=ru&ah=hTJ2ldlr8IghgG9qpTc0ZEwywsU'}
                  target={'_blank'}
                  rel={'nofollow'}
              ><img src={'/img/google play.png'}/></a></div>
            </div>
          </Grid.Column>
          <Grid.Column width={4} style={{paddingTop: '110px', paddingLeft: '0', paddingRight: '0'}}>
            <div>
              <a href={'/searchByAccessories'}>
                <img src={'/img/icon-individual-search.svg'} alt={'Поиск оригинальных аксуссуаров'}/>
              </a>
              <div style={{fontWeight: 'bold', fontSize: '18px', marginBottom: '20px', marginTop: '30px'}}>Индивидуальный поиск</div>
              <div>Поиск запчестей и акссесуаров под Ваш личный автомобиль</div>
            </div>
          </Grid.Column>
          <Grid.Column width={4} style={{paddingTop: '110px'}}>
            <div className={'switch-item'}>
              <a href={'#'}>
                <img src={'/img/icon-cart-full.svg'} alt={'Поиск оригинальных аксуссуаров'}/>
              </a>
              <div style={{fontWeight: 'bold', fontSize: '18px', marginBottom: '20px', marginTop: '30px'}}>Корзина сохраняется</div>
              <div>Все ваши заказы сохраняются в Личном кабинете в корзине</div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
    <Grid style={{marginTop: '172px', height: '500px'}}>
      <Grid.Row>
        <img src={'/img/web lk 1.png'} style={{position: 'absolute', top: '0', right: '-110px'}}/>
        <Grid.Column width={4}>
          <div className={'switch-item'}>
            <a href={'#'}>
              <img src={'/img/box-with-check.svg'} alt={'Поиск оригинальных аксуссуаров'}/>
            </a>
            <div style={{fontWeight: 'bold', fontSize: '18px', marginBottom: '20px', marginTop: '30px'}}>Заказ любого товара</div>
            <div>Вы сможете заказать временно отсутсвующие позиции</div>
          </div>
        </Grid.Column>
        <Grid.Column width={4}>
          <div className={'switch-item'}>
            <a href={'#'}>
              <img src={'/img/first-position.svg'} alt={'Поиск оригинальных аксуссуаров'}/>
            </a>
            <div style={{fontWeight: 'bold', fontSize: '18px', marginBottom: '20px', marginTop: '30px'}}>Только Ваш дилер</div>
            <div>Просмотр наличие только у Вашего дилера</div>
          </div>
        </Grid.Column>
        <Grid.Column width={8}>
          <div style={{width: '223px', textAlign: 'left', position: 'absolute', left: '27px', top: '16px'}}>
            <div style={{fontWeight: 'bold', fontSize: '18px', marginBottom: '20px'}}>Личный кабинет</div>
            <div style={{}}><a href={'https://lk.chery.ru/'} target={'_blank'}><img src={'/img/login.svg'}/></a></div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>;
}

export default StartPage;
