import './dealersForProduct.css';
import {Button, Dropdown, Grid, Table} from "semantic-ui-react";
import {pluralize} from "numeralize-ru";
import CitySelector from "../citySelector/citySelector";
import {addToCart, getCart, setProductCount} from "../../utils/cartHelper";
import {useEffect, useState} from "react";
import {api} from "../../utils/api";
import CountInput from "../countInput/countInput";

export default function DealersForProduct({product, onChange}) {
    let [dealersData, setDealersData] = useState([]);
    let [cart, setCart] = useState([]);
    let [cityForSearch, setCityForSearch] = useState(null);
    
    let loadDealers = (cityForSearch) => {
        api()
            .getDealersForArticle(product.article, cityForSearch)
            .then((data)=> {
                setDealersData(data.map(x=> {
                    return {
                        id: x.DealerId,
                        name: x.DealerName,
                        address: x.Address,
                        phone: x.Phone,
                        price: x.Price,//x.Price == null ? '-' : (x.Price.toLocaleString('ru-RU') + ',00'),
                        atDate: new Date(x.UploadDate).toLocaleDateString('ru-RU'),
                    };
                }))
            });
    };
    
    useEffect(()=>{
        setCart(getCart());
        loadDealers(cityForSearch);
    }, []);
    
    return <div>
      <Grid>
          <Grid.Row>
              <Grid.Column width={9} className={'header-label-container'}>
                  <span className={'header-label'}>Наличие у диллеров</span>
                  <span className={'result-count-label'}>&nbsp;&nbsp;&nbsp;&nbsp;  {dealersData.length} {pluralize(dealersData.length, 'результат', 'результата', 'результатов')}</span>
              </Grid.Column>
              <Grid.Column width={7}>
                  <CitySelector 
                      value={cityForSearch} 
                      onChange={(val) => {setCityForSearch(val); loadDealers(val);}} />
              </Grid.Column>
          </Grid.Row>
      </Grid>
      <div className={'dealers-table'}>
          <Table celled striped>
              <Table.Header>
                  <Table.Row>
                      <Table.HeaderCell>Дилер</Table.HeaderCell>
                      <Table.HeaderCell>Адрес</Table.HeaderCell>
                      <Table.HeaderCell>Телефон</Table.HeaderCell>
                      <Table.HeaderCell>Цена (руб.)</Table.HeaderCell>
                      <Table.HeaderCell>Актуально на</Table.HeaderCell>
                      <Table.HeaderCell>В корзину</Table.HeaderCell>
                  </Table.Row>
              </Table.Header>
              <Table.Body>
                  {dealersData.map(dealer => {
                      let productsInCartForDealer = cart.filter(cartItem => cartItem.dealerId === dealer.id && cartItem.productId === product.id);
                      return <Table.Row className={'dealer-item-in-table'}>
                          <Table.Cell>{dealer.name}</Table.Cell>
                          <Table.Cell>{dealer.address}</Table.Cell>
                          <Table.Cell>{dealer.phone}</Table.Cell>
                          <Table.Cell>{dealer.price == null ? '-' : (dealer.price.toLocaleString('ru-RU') + (dealer.price.toLocaleString('ru-RU').includes(',') ? '' : ',00'))}</Table.Cell>
                          <Table.Cell>{dealer.atDate}</Table.Cell>
                          <Table.Cell className={'count-editor-container' + (productsInCartForDealer.length > 0 ? ' focused' : '')}>
                              {productsInCartForDealer.length > 0 ?
                                  <div><CountInput value={productsInCartForDealer[0].count} onChange={(val, isAdding)=>{
                                      setProductCount(dealer.id, product.id, val);
                                      setCart(getCart());
                                      onChange(isAdding);
                                  }}/></div> : <Button className={'add-btn-in-table'} icon={'plus'} onClick={() => {
                                      addToCart(dealer.name, dealer.id, product.name, product.id, dealer.price);
                                      setCart(getCart());
                                      onChange(true);
                                  }}/>}
                          </Table.Cell>
                      </Table.Row>;
                  })}
              </Table.Body>
          </Table>
      </div>
    </div>;
}